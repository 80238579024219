import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getFromLocalStorage } from './utils';

const OrderWebSocket = ({
    updateOrderData
}) => {
    const { sendJsonMessage, lastMessage, readyState } = useWebSocket('wss://1j21icendk.execute-api.ap-southeast-1.amazonaws.com/staging', {
        onOpen: () => {
            const hubCode = getFromLocalStorage('hub').value;
            sendJsonMessage({"action":"init", "hubCode": hubCode}, []);
        },
        onClose: () => console.log('Socket closed'),
        onError: () => console.log('Socket Error'),
    });

    useEffect(() => {
        if (lastMessage !== null) {
            updateOrderData(lastMessage.data);
        }
    }, [lastMessage]);
    
    return null;
}
export default OrderWebSocket;